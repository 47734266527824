<template>
    <v-btn
        :outlined="outlined"
        color="primary"
        class="poppins f13 fw500 text-capitalize"
        @click="$emit('click')"
        dense
        :loading="loading"
        :disabled="disabled"
        :block="block"
    >
        <v-icon small left v-if="icon">
            {{ icon }}
        </v-icon>
        {{ label }}
    </v-btn>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: ""
        },
        outlined: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String || null,
            default: null
        },
        block: {
            type: Boolean,
            default: false
        }
    },
}
</script>
